<script setup lang="ts">
  import { EyeIcon, HeartIcon } from '@heroicons/vue/24/solid'
  import StarRating from '~/components/vendor/star-rating.vue'
  import { useStringHelpers } from '~/composables/useStringHelpers'
  import { useUrlHelpers } from '~/composables/useUrlHelpers'
  import CardTag from '~/components/cards/CardTag.vue'
  import CardFavorite from '~/components/cards/CardFavorite.vue'

  const props = defineProps({
    space: {
      type: Object,
      required: true,
      default: () => {},
    },
  })

  const { space } = toRefs(props)
  const { truncateString, getCloudinaryImageName } = useStringHelpers()
  const spaceNameMaxLength = 24
  const spaceUrl = ref('')

  // Format price to Brazilian currency
  const formatPrice = (price: number) => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(price)
  }

  onMounted(() => {
    spaceUrl.value = useUrlHelpers().getSpaceUrl(space?.value)
  })
</script>

<template>
  <div
    class="w-full p-4 sm:ml-3 sm:w-11/12 sm:p-0 md:ml-3 md:w-11/12 md:p-0 xl:w-11/12">
    <NuxtLink
      :href="spaceUrl"
      class="c-card block overflow-hidden rounded-lg bg-white shadow-md hover:shadow-xl">
      <div class="relative overflow-hidden pb-48">
        <CardFavorite :space-id="space.id" />
        <CardTag :tags="space.tags" />
        <!-- Price badge -->
        <div
          v-if="space.prices?.eventPrice"
          class="absolute bottom-2 right-2 z-10 rounded-sm bg-green-100 px-2 py-0.5 text-xs font-semibold text-green-800">
          {{ formatPrice(space.prices.eventPrice) }}
        </div>
        <NuxtImg
          provider="cloudinary"
          preset="cover"
          preload
          loading="lazy"
          class="absolute inset-0 z-auto h-full w-full transform object-cover transition-transform duration-300 ease-in-out hover:scale-110"
          :src="getCloudinaryImageName(space.coverPhotoUrl)"
          :alt="space.name" />
      </div>
      <div class="p-4 pt-2">
        <span
          class="inline-block rounded-full text-xs font-semibold uppercase leading-none tracking-wide">
          <EyeIcon class="inline h-4 text-blue-600" /><span class="mr-1"
            >&nbsp;{{ space.visualizations }}</span
          >
          <HeartIcon class="inline h-4 text-red-600" /><span class="mr-1"
            >&nbsp;{{ space.favorites ?? 0 }}</span
          >
        </span>
        <h2 class="mt-2 text-xs font-semibold uppercase">
          {{ truncateString(space.name, spaceNameMaxLength) }}
        </h2>
      </div>
      <div class="border-b border-t p-4 text-xs text-gray-700">
        <span class="mb-1 flex items-center">
          {{ space.address.route }}
        </span>
        <span class="flex items-center">
          {{ space.address.neighborhood }} / {{ space.address.city }}
        </span>
      </div>
      <div class="flex items-center p-4 text-sm text-gray-600">
        <ClientOnly>
          <StarRating
            v-model:rating="space.rating"
            :increment="0.1"
            :read-only="true"
            :star-size="14"
            active-color="rgb(234 179 8 / var(--tw-text-opacity))" />
        </ClientOnly>
        <span class="ml-2 text-xs text-gray-700">
          de {{ space.totalRatings }} avaliações</span
        >
      </div>
    </NuxtLink>
  </div>
</template>
